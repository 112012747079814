import React from "react";
import "./Policy.scss";
import Navigation from "../../component/Navigation/Navigation";
import Footer from "../../component/footer/Footer";
// import ContactForm from "../../../component/ContactForm/ContactForm";

const Policy = () => {
  return (
    <section className="policy_W">
      <Navigation />
      <div className="policy_C">
        <div className="policy_C_header">
          <span>Cookie Policy</span>
        </div>
        <div className="policy_Content">
          <span className="textTitle">What are cookies?</span>
          <br />
          <br />
          <span className="textDes">
            <span>A cookie</span> is a small file, typically consisting of
            letters and numbers, downloaded onto your device (computer or mobile
            phone) when you access Stigmata Techno Solution's website.{" "}
            <span>Cookies enable Stigmata Techno Solution</span> to recognize
            your preferences and store them, saving you the time and effort of
            repeatedly re-entering the same information.
            <br />
            <br />
            The cookies on Stigmata Techno Solution's website are there to
            provide you with more relevant information and enhance your browsing
            experience. They also help Stigmata Techno Solution to improve its
            website.
            <br />
            <br />
            <span>Please note</span> that cookies by themselves do not reveal
            your personal information but do contain certain unique identifiers.
            These unique identifiers, in combination with other unique
            identifiers and information obtained from other sources, may
            generate personal information about your identity.
            <br />
            <br />
            <span>
              What does Stigmata Techno Solution collect and do using cookies?
            </span>
            <br />
            <br />
            Cookies used by Stigmata Techno Solution collect information
            concerning your IP address, web pages visited by you, and the time
            of such visits. In certain instances, information collected by
            cookies will reflect your preferences and choices or follow your
            activity when you revisit Stigmata Techno Solution's website.
            <br />
            <br />
            Sometimes you may follow an external link from a third-party source
            (either an affiliate or not) that directs you to Stigmata Techno
            Solution's website. In such cases, Stigmata Techno Solution stores
            information about your arrival from the external link in the form of
            a session cookie.
            <br />
            <br />
            <span>
              Do you need cookies for browsing and accessing Stigmata Techno
              Solution's website?
            </span>
            <br />
            <br />
            You do not need to have cookies turned on to visit Stigmata Techno
            Solution's website, and you can opt out of them by turning them off
            in your browser settings. However, doing so will affect your
            browsing experience. If you want to access Stigmata Techno
            Solution's web pages that require registration, you will need to
            accept a mandatory session cookie, which you can delete after you
            leave our website.
            <br />
            <br />
            Does Stigmata Techno Solution use third-party analytics cookies?
            <br />
            <br />
            Stigmata Techno Solution uses analytics tools and other third-party
            technologies, such as Google Analytics and HubSpot cookies, for
            collecting information in the form of usage and user metrics when
            you use Stigmata Techno Solution's website and/or services. These
            tools and technologies collect and analyze certain types of
            information, including cookies, IP addresses, device and software
            identifiers, referral and exit URLs, onsite behavior and usage
            information, feature use metrics and statistics, usage and purchase
            history, MAC Address, mobile unique device ID, and other similar
            information.
            <br />
            <br />
            The third-party analytics companies that collect information on
            Stigmata Techno Solution's website may combine the information with
            other information that they may have independently collected about
            your browsing and internet activities from several other websites
            that use analytics tools as well as their own online products and
            services. These companies collect and use such information under
            their own privacy policies, and Stigmata Techno Solution cannot be
            held responsible for their data protection and privacy measures.
            Google Analytics’ terms of utilizing your data can be accessed in
            their Terms of Service. You may opt out of Google Analytics
            utilizing your data by installing the Google Analytics opt-out
            browser add-on. HubSpot provides information regarding the usage of
            your data in their Cookie Policy and Privacy Policy.
            <br />
            <br />
            <span>
              Will social networking sites record cookies on Stigmata Techno
              Solution's website?
            </span>
            <br />
            <br />
            Stigmata Techno Solution's website provides functions for sharing
            content on social networks. If you use any of these functions, the
            social network operator might collect information such as your IP
            address, details of the pages that you have visited, or record the
            cookies. Such collection of information is regulated by the privacy
            policy of these social network companies that provide these
            functions. You must review them to understand how your data will be
            protected by social networks.
            <br />
            <br />
            <span>
              Can I manage the use of Stigmata Techno Solution cookies?
            </span>
            <br />
            <br />
            You do not have to accept cookies, and you may modify your browser
            settings to refuse them. The browser you use allows you to see,
            identify, and control the use of website cookies, including Stigmata
            Techno Solution cookies.
            <br />
            <br />
            You can revoke your previously provided consent at any time and
            control the cookies through your browser, deleting them individually
            or deleting all of them. You can also set your browser to not accept
            any cookies. If this option is selected, you should be aware that
            many websites may not function properly, but this is not the case
            with Stigmata Techno Solution's website. Instead of setting up your
            browser to refuse/block cookies, you could set it up so that your
            consent is required before each cookie is set on your device. This
            gives you more control over what is set on your device without
            excessively disrupting or compromising the quality of your browsing
            experience. There are different levels of control that you can
            exercise in using your cookies. You will be able to prevent only
            third-party cookies from being deployed or block specific companies
            from deploying a cookie on your device. To manage and control your
            cookies, please select your browser from the list below and follow
            the instructions:
            <br />
            <br />
            In case you have any additional queries regarding Stigmata Techno
            Solution's cookie usage or policy, please write to us at privacy{" "}
            <a href="">jerome@stigmatatech.com</a> Any changes in this policy
            will be published on this website.
          </span>
        </div>
      </div>
      {/* <ContactForm /> */}
      <Footer />
    </section>
  );
};

export default Policy;
