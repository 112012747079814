import React from 'react'
import "./Blogs.scss"

const Blogs = () => {
  return (
    <div style={{
        width:'100%',
        height:'500px',
        
    }}>
        
        <h1>Blogs Page</h1></div>
  )
}

export default Blogs