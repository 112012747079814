import React from "react";
import ExampleCarouselImage from "./ExampleCarouselImage";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HoverCard from "../HoverCard/HoverCard";
import BgSlider from "./BgSlider/BgSlider";


const ControlledCarousel = () => {


  return (
  
 < div>
 <BgSlider />

<HoverCard />
 </div>
     
  );
};

export default ControlledCarousel;


